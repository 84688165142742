import { getCdnUrl } from '@features/common/store/common.selectors';
import classNames from 'classnames';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { ITournamentProps } from '../models/tournament.model';

const TournamentMerchantView = (props: Pick<ITournamentProps, 'tournament' | 'tournamentType' | 'theme'>) => {
	const { tournament, tournamentType, theme } = props;
	const cdnUrl = useSelector(getCdnUrl);

	return (
		<>
			<div
				className={classNames('flex items-center', {
					'mt-[3px]': tournamentType === 'registeredTournament',
					'sm:mt-[3px]': tournamentType !== 'registeredTournament',
				})}
			>
				<div className='min-w-[20px] min-h-[20px] relative mr-[5px] rounded-full overflow-hidden bg-white'>
					<Image
						unoptimized={true}
						src={`${cdnUrl}files/${tournament.merchant.profileImageId}.jpeg`}
						objectFit='contain'
						layout='fill'
						priority={true}
						alt={tournament.merchant.name}
					/>
				</div>
				<p
					className={classNames('font-medium text-msm not-italic truncate', {
						'text-dark-500': theme === 'light',
						'text-white': theme === 'dark',
					})}
					style={{
						fontFamily: 'Roboto',
					}}
				>
					{tournament.merchant.name}
				</p>
			</div>
		</>
	);
};

export default TournamentMerchantView;
