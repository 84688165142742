import classNames from 'classnames';
import { ITournamentBoxProps } from '../models/tournament.model';
import IconAwardCup from '@features/common/components/IconAwardCup';

export interface ITournamentAwardInfo
	extends Omit<ITournamentBoxProps, 'cdnUrl' | 'tournamentName' | 'tournament' | 'viewHref' | 'tournamentType'> {
	awardName: string;
}

const TournamentAwardInfo = (props: ITournamentAwardInfo) => {
	const { awardName, theme } = props;

	return (
		<p
			className={classNames('flex w-full text-sm font-bold truncate leading-5 gap-1', {
				'text-dark-300': theme === 'light',
				'text-white': theme === 'dark',
			})}
			title={`1. ${awardName}`}
		>
			<IconAwardCup width={'15.93'} height={'15.93'} />
			<span className='flex-1 truncate'>{awardName}</span>
		</p>
	);
};

export default TournamentAwardInfo;
