import classNames from 'classnames';
import { ITournamentProps } from '../models/tournament.model';
import TournamentTimeRemaining from './TournamentTimeRemaining';
import { useSelector } from 'react-redux';
import { getCdnUrl } from '@features/common/store/common.selectors';
import Image from 'next/image';

const TournamentBoxImageView = (props: Pick<ITournamentProps, 'tournament' | 'tournamentType'>) => {
	const { tournament, tournamentType } = props;
	const cdnUrl = useSelector(getCdnUrl);

	return (
		<>
			<Image
				unoptimized={true}
				src={`${cdnUrl}files/${tournament.logoId}.jpeg`}
				objectFit='cover'
				layout='fill'
				priority={true}
				alt={tournament.name}
			/>
			{tournament.statusId !== 'FINISHED' ||
			(tournament.statusId === 'FINISHED' && tournament.tournamentType === 'PLAY_NOW_TEMPLATE') ? (
				<div
					className={classNames('w-full h-7 flex justify-start mt-auto', {
						'bg-brightGray-400 bg-opacity-70':
							tournament.tournamentType !== 'PLAY_NOW_TEMPLATE' &&
							tournament.tournamentType !== 'PLAY_NOW_COPY',
						'bg-headerColor-300':
							tournament.tournamentType &&
							(tournament.tournamentType === 'PLAY_NOW_TEMPLATE' ||
								tournament.tournamentType === 'PLAY_NOW_COPY') &&
							tournament.awardName === null,
					})}
				>
					<TournamentTimeRemaining
						registered={tournament.registered}
						registrationEndDate={tournament.registrationEndDate}
						tournamentType={tournamentType}
						isPopupRegisteredSuccess={false}
						tournamentStartDate={tournament.tournamentStartDate}
						statusId={tournament.statusId}
						gamyType={tournament.tournamentType}
						withAwards={!!tournament.awardName}
					/>
				</div>
			) : null}
		</>
	);
};

export default TournamentBoxImageView;
