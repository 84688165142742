import { ITournamentProps } from '@features/tournaments/models/tournament.model';
import classNames from 'classnames';
import Link from 'next/link';
import TournamentBoxImageView from './TournamentBoxImageView';
import TournamentLabel from './TournamentLabel';
import TournamentInformationContainer from './TournamentInformationContainer';
import TournamentBoxRegistrationButton from './TournamentBoxRegistrationButton';

const Tournament = (props: ITournamentProps) => {
	const { tournament, tournamentStyles, tournamentType, theme } = props;

	const getCorrectView = () => {
		if (tournament.tournamentType === 'PLAY_NOW_TEMPLATE') {
			return `/championships/${tournament.id}/view`;
		}
		if (tournamentType === 'myRegisteredTournaments') {
			return `/tournaments/${tournament.id}/play`;
		}

		return `/tournaments/${tournament.id}/view`;
	};

	const getTitleLinesCount = () => {
		if (tournament.name.length < 24) {
			return 1;
		}
		return 2;
	};

	const getDescriptionLineClamp = () => {
		if (tournament.awardName && getTitleLinesCount() === 2) {
			return 'line-clamp-2';
		}
		if (
			(tournament.awardName && getTitleLinesCount() === 1) ||
			(tournament.awardName === null && getTitleLinesCount() === 2)
		) {
			return 'line-clamp-3';
		}
		return 'line-clamp-4';
	};

	return (
		<div
			className={classNames(
				`sm:w-[261px] sm:h-[429px] w-full h-[126px] rounded-xl sm:rounded-3xl shadow-3xl flex flex-col justify-start hover:shadow-4xl transition-shadow bg-white sm:m-0 ${
					tournamentStyles ? tournamentStyles : ''
				}`,
				{
					'bg-white': theme === 'light',
					'bg-[#24273E]': theme === 'dark',
				}
			)}
			data-testid='tournament'
		>
			<div className='flex flex-col flex-1 justify-start cursor-pointer relative'>
				<div className='flex-row sm:flex-col flex flex-1 w-full cursor-pointer p-2 sm:p-0'>
					<Link href={getCorrectView()}>
						<a className='block'>
							<div className='sm:rounded-t-3xl w-[170px] h-[110px] min-w-[170px] sm:w-full  sm:aspect-h-9 aspect-w-14 aspect-w-1 bg-white overflow-hidden '>
								<TournamentBoxImageView tournament={tournament} tournamentType={tournamentType} />
							</div>
						</a>
					</Link>
					{tournament.tournamentType !== 'PLAY_NOW_COPY' &&
					(tournament.awardName || tournament.championshipId) ? (
						<TournamentLabel tournament={tournament} tournamentType={tournamentType} />
					) : null}
					<div className='flex-col flex-1 flex sm:hidden overflow-hidden'>
						<TournamentInformationContainer
							viewHref={getCorrectView()}
							tournament={tournament}
							tournamentStyles={getDescriptionLineClamp()}
							theme={theme}
						/>
					</div>
					<div className='hidden sm:flex-col sm:flex h-full'>
						<TournamentInformationContainer
							viewHref={getCorrectView()}
							tournament={tournament}
							tournamentStyles={getDescriptionLineClamp()}
							theme={theme}
						/>
					</div>
				</div>
			</div>
			<div className='sm:block hidden'>
				<TournamentBoxRegistrationButton
					tournamentType={'merchantTournaments'}
					tournament={tournament}
					theme={theme}
				/>
			</div>
		</div>
	);
};

export default Tournament;
