import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ITournamentProps } from '../models/tournament.model';
import TournamentRegistrationButton from './TournamentRegistrationButton';

const TournamentBoxRegistrationButton = (props: Omit<ITournamentProps, 'cdnUrl'>) => {
	const { tournament, tournamentType, theme } = props;
	const [isMaxParticipantsLimitReached, setIsMaxParticipantsLimitReached] = useState<boolean>(false);

	useEffect(() => {
		if (tournament.maxParticipantsLimit !== tournament.participantsCount) {
			return;
		}
		setIsMaxParticipantsLimitReached(true);
	}, [tournament.maxParticipantsLimit, tournament.participantsCount]);

	return (
		<div
			className={classNames('sm:p-[14px] items-center', {
				'w-full': isMaxParticipantsLimitReached || tournament.statusId === 'FINISHED',
				'sm:w-full s:w-1/2 w-full': tournament.statusId !== 'FINISHED' && !isMaxParticipantsLimitReached,
			})}
		>
			<TournamentRegistrationButton
				tournamentId={tournament.id}
				tournamentStatusId={tournament.statusId}
				isParticipantRegistered={tournament.registered}
				isMaxParticipantsLimit={isMaxParticipantsLimitReached}
				tournamentAccess={tournament.entranceTypeId}
				tournamentName={tournament.name}
				isTournamentView={false}
				typeTournament={tournamentType}
				gamyType={tournament.tournamentType}
				theme={theme}
				latestActiveCopyId={undefined}
			/>
		</div>
	);
};

export default TournamentBoxRegistrationButton;
