import React from 'react';

interface IJoyStickIconProps {
	color: string;
}

const JoyStickIcon = ({ color }: IJoyStickIconProps) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='18' height='13' viewBox='0 0 18 13' fill='none'>
			<path
				d='M6.07946 9.941C4.28221 9.941 3.60824 11.983 1.81099 12.8906C0.0137319 12.8906 -1.33421 10.1679 2.2603 2.45349H2.48496L2.88035 1.70021C2.88035 1.70021 5.40549 0.184553 6.60067 1.30087H11.3993C12.5945 0.184553 15.1196 1.70021 15.1196 1.70021L15.515 2.45349H15.7397C19.3342 10.1679 17.9863 12.8906 16.189 12.8906C14.3918 11.983 13.7178 9.941 11.9205 9.941H6.07946ZM9 1.99971C8.76167 1.99971 8.5331 2.09533 8.36457 2.26553C8.19605 2.43573 8.10137 2.66658 8.10137 2.90728C8.10137 3.14799 8.19605 3.37883 8.36457 3.54904C8.5331 3.71924 8.76167 3.81486 9 3.81486C9.23833 3.81486 9.4669 3.71924 9.63542 3.54904C9.80395 3.37883 9.89863 3.14799 9.89863 2.90728C9.89863 2.66658 9.80395 2.43573 9.63542 2.26553C9.4669 2.09533 9.23833 1.99971 9 1.99971Z'
				fill={color}
			/>
		</svg>
	);
};

export default JoyStickIcon;
