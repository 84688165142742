import IconParticipantsTournamentBox from '@features/common/components/IconParticipantsTournamentBox';
import JoyStickIcon from '@features/common/components/JoyStickIcon';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { ITournamentProps } from '../models/tournament.model';

const TournamentParticipantAndQuestionView = (props: Omit<ITournamentProps, 'cdnUrl'>) => {
	const { tournamentType, tournament, theme } = props;
	const { formatMessage } = useIntl();

	return (
		<div
			className={classNames('flex justify-between w-full items-center text-sm mt-[7px]', {
				'text-grayBlue-700': theme === 'light',
				'text-white': theme === 'dark',
			})}
		>
			<div
				className={`flex items-center ${
					tournamentType === 'defaultTournaments' ? 'ml-[3px] font-normal' : 'sm:ml-[3px]'
				}`}
			>
				{tournament.tournamentType !== 'PLAY_NOW_COPY' ? (
					<>
						<div className='w-[16px] h-5 mr-[3px] flex items-center'>
							{tournament.tournamentType && tournament.tournamentType === 'PLAY_NOW_TEMPLATE' ? (
								<JoyStickIcon color={theme === 'dark' ? '#FFFFFF' : '#808191'} />
							) : (
								<IconParticipantsTournamentBox />
							)}
						</div>
						<p className='font-normal'>{tournament.participantsCount}</p>
					</>
				) : null}
			</div>
			<p className='font-normal'>
				{tournament.playsPerRound} {formatMessage({ id: 'bcs-Gameplay-Questions' })}
				{', '}
				{tournament.answerTimeMillis / 1000} {formatMessage({ id: 'bcs-Gameplay-Sec' })}.
			</p>
		</div>
	);
};

export default TournamentParticipantAndQuestionView;
