import Link from 'next/link';
import { ITournamentBoxProps } from '../models/tournament.model';
import classNames from 'classnames';
import TournamentAwardInfo from './TournamentAwardInfo';
import TournamentBoxRegistrationButton from './TournamentBoxRegistrationButton';
import TournamentMerchantView from './TournamentMerchantView';
import TournamentParticipantAndQuestionView from './TournamentParticipantAndQuestionView';

const TournamentInformationContainer = (props: ITournamentBoxProps) => {
	const { tournament, tournamentStyles, viewHref, theme } = props;
	return (
		<>
			<Link href={viewHref}>
				<a className='flex flex-col h-full'>
					<div className='flex flex-col justify-start sm:pt-[10px] sm:pb-0 sm:px-[14px] px-[7px] pt-[1px]'>
						<p
							className={classNames(
								'line-clamp-2 sm:h-full h-8 font-medium break-words text-xsm sm:text-xbase text-dark-500',
								{ 'text-dark-500': theme === 'light', 'text-white': theme === 'dark' }
							)}
							style={{
								fontFamily: 'Roboto',
							}}
							title={`${tournament.name}`}
						>
							{tournament.name}
						</p>

						<TournamentMerchantView tournament={tournament} theme={theme} />
						<div className='hidden sm:block'>
							<p
								className={classNames(`font-normal break-words pt-[4px] text-xsm ${tournamentStyles}`, {
									'text-backgroundGray-400': theme === 'light',
									'text-white': theme === 'dark',
								})}
								title={`${tournament.description}`}
							>
								{tournament.description}
							</p>
						</div>
					</div>
					<div
						className='w-full sm:justify-end sm:flex-1 sm:h-full sm:flex sm:flex-col hidden'
						style={{ padding: '0px 16px 0px 16px' }}
					>
						{tournament.awardName ? (
							<TournamentAwardInfo awardName={tournament.awardName} theme={theme} />
						) : null}

						<TournamentParticipantAndQuestionView
							tournamentType={'merchantTournaments'}
							tournament={tournament}
							theme={theme}
						/>
					</div>
				</a>
			</Link>

			<div className='pl-[0.4375rem] sm:left-0 sm:hidden block mt-[6px]'>
				<TournamentBoxRegistrationButton
					tournamentType={'merchantTournaments'}
					tournament={tournament}
					theme={theme}
				/>
			</div>
		</>
	);
};

export default TournamentInformationContainer;
