import IconEmptyPage from '@features/common/components/IconEmptyPage';
import LoadingIndicator from '@features/layout/components/LoadingIndicator';
import { useIntl } from 'react-intl';
import { ITournamentCompact, TournamentTypes } from '../models/tournament.model';
import Tournament from './Tournament';
import CommonTooltip from '@features/common/components/CommonTooltip';
import Link from 'next/link';
import InformationIconButton from '@features/common/components/InformationIconButton';

interface TournamentListingProps {
	loadMore: () => void;
	tournamentTitle: string;
	tournaments: ITournamentCompact[];
	totalTournaments: number | null;
	isTournamentsLoading: boolean;
	skip: number;
	take?: number;
	noTournamentsMessage?: string;
	tournamentStyles?: string;
	typeTournament: TournamentTypes;
	isPlayNowAllSection?: boolean;
	showTooltip?: boolean;
}

const TournamentsListing = (tournamentListingProps: TournamentListingProps) => {
	const {
		loadMore,
		tournaments,
		skip,
		totalTournaments,
		isTournamentsLoading,
		tournamentTitle,
		tournamentStyles,
		noTournamentsMessage,
		typeTournament,
		isPlayNowAllSection,
		showTooltip,
	} = tournamentListingProps;
	const { formatMessage } = useIntl();

	const playNowNavigation = formatMessage(
		{ id: 'bcs-Play-Now-Tooltip-Awards-Content' },
		{
			learnMore: (
				<Link href={'/how-gamy-works#PlayNowGamys'}>
					<a className='underline cursor-pointer font-bold text-xs' style={{ fontFamily: 'Roboto' }}>
						{formatMessage({ id: 'bcs-Play-Now-Tooltip-Awards-Learn-More' })}
					</a>
				</Link>
			),
			br: <br />,
		}
	);

	const getHeaderSectionStyle = (): string => {
		if (typeTournament === 'merchantTournaments' || typeTournament === 'championshipTournaments') {
			return 'font-bold text-dark-700 sm:text-1.5xl text-xlg';
		}

		if (typeTournament === 'playNowChampionshipTournaments') {
			return 'text-dark-700 sm:text-lg text-base';
		}

		if (typeTournament === 'myRegisteredTournaments') {
			return 'font-medium text-brightGray-400 text-xbase leading-8 not-italic';
		}

		return 'text-xl';
	};

	const getTournamentGridStyle = (): string => {
		if (typeTournament === 'merchantTournaments' || typeTournament === 'playNowChampionshipTournaments') {
			return '1xl:grid-cols-[repeat(4,minmax(0,1fr))] xl:grid-cols-[repeat(3,minmax(0,1fr))] 4xl:grid-cols-6 3xl:grid-cols-5 2xl:grid-cols-4 auto-cols-max justify-items-start sm:gap-8 gap-5 sm:w-max w-full';
		}

		if (typeTournament === 'championshipTournaments') {
			return 'xl:grid-cols-[repeat(3,minmax(0,1fr))] 4xl:grid-cols-6 3xl:grid-cols-5 2xl:grid-cols-4 auto-cols-max justify-items-start sm:gap-8 gap-5';
		}

		return 'xl:grid-cols-[repeat(4,minmax(0,1fr))] justify-center justify-items-center sm:gap-8 gap-5 sm:mb-10';
	};

	const getTournamentMainContainerStyle = (): string => {
		if (
			typeTournament === 'merchantTournaments' ||
			typeTournament === 'championshipTournaments' ||
			typeTournament === 'playNowChampionshipTournaments'
		) {
			return 'justify-between items-start';
		}

		if (typeTournament === 'myRegisteredTournaments') {
			return 'justify-start sm:items-center items-start md:space-x-5';
		}
		if (noTournamentsMessage) {
			return 'mt-10 sm:ml-0 px-4 sm:px-7';
		}
		return 'justify-start sm:mt-10 mt-5 sm:items-center items-start sm:ml-0 px-4 sm:px-7 md:space-x-5 sm:w-max';
	};

	const getNoTournamentMessageStyle = (): string => {
		if (
			typeTournament === 'merchantTournaments' ||
			typeTournament === 'championshipTournaments' ||
			typeTournament === 'playNowChampionshipTournaments'
		) {
			return 'text-left font-normal tracking-small text-brightGray-500 ml-0 sm:text-xlg text-1xsbase font-sans not-italic w-full sm:mt-[34px] mt-[14px] mb-[94px]';
		}
		return 'text-left self-start';
	};

	const getAllGamysTournamentsStyle = (): string => {
		if (typeTournament === 'defaultTournaments' && totalTournaments && totalTournaments < skip) {
			return 'sm:mb-0 mb-[50px]';
		}
		if (
			typeTournament === 'playNowChampionshipTournaments' &&
			totalTournaments &&
			totalTournaments < skip &&
			!isPlayNowAllSection
		) {
			return '2xs:mb-12 lg:mb-16';
		}
		return '';
	};

	return (
		<div className={`flex flex-col w-full ${getAllGamysTournamentsStyle()}`}>
			<div className={`flex flex-col sm:items-start w-full ${getTournamentMainContainerStyle()}`}>
				{!tournaments.length ? (
					<h2
						className={`text-left col-span-full lg:mt-[15px] lg:mb-[30px] w-full mb-5 ml-0 self-start ${getHeaderSectionStyle()}`}
						style={{ fontFamily: 'Montserrat' }}
					>
						{tournamentTitle}
					</h2>
				) : null}
				{tournaments.length ? (
					<>
						<div
							className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full sm:w-[unset] ${getTournamentGridStyle()}`}
							style={{ marginLeft: '0px', zIndex: '1' }}
						>
							<div
								className={`flex col-span-full w-full ml-0 self-start ${getHeaderSectionStyle()}`}
								style={{ fontFamily: 'Montserrat' }}
							>
								{showTooltip ? (
									<>
										<CommonTooltip
											className='flex items-center justify-start'
											text={playNowNavigation as string}
											type='contained'
											pageView='Homepage'
										>
											{tournamentTitle}
											<div className='flex sm:ml-[15px] ml-2'>
												{isPlayNowAllSection ? (
													<InformationIconButton width={19} height={19} color='white' />
												) : (
													<InformationIconButton width={19} height={19} color='#808191' />
												)}
											</div>
										</CommonTooltip>
									</>
								) : (
									<>{tournamentTitle}</>
								)}
							</div>

							{tournaments.map((tournament) => (
								<Tournament
									tournament={tournament}
									key={tournament.id}
									tournamentStyles={tournamentStyles}
									tournamentType={typeTournament}
									theme={isPlayNowAllSection ? 'dark' : 'light'}
								/>
							))}
						</div>

						{!isTournamentsLoading && totalTournaments && totalTournaments > skip ? (
							<div className='relative flex w-full justify-center h-20 my-[30px] items-center'>
								<button
									type='button'
									className='absolute py-4 px-6 h-14 bg-brightGray-200 text-brightGray-400 text-mxsm transition-colors
								font-bold rounded-2xl 
								focus:outline-none hover:bg-main-500 hover:text-white disabled:cursor-default disabled:bg-gray-200 disabled:text-black min-w-[132px] z-10'
									onClick={loadMore}
									data-testid='loadMoreBtn'
								>
									{formatMessage({
										id: 'bcs-Common-LoadMore',
									})}
								</button>
							</div>
						) : null}
						{isTournamentsLoading && totalTournaments !== null ? (
							<div className='flex w-full h-full items-center justify-center'>
								<LoadingIndicator contained={true} />
							</div>
						) : null}
					</>
				) : noTournamentsMessage ? (
					<p className={`${getNoTournamentMessageStyle()}`}>{noTournamentsMessage}</p>
				) : (
					<IconEmptyPage />
				)}
			</div>
		</div>
	);
};

export default TournamentsListing;
