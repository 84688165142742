import React from 'react';
import { useIntl } from 'react-intl';
import { ITournamentCompact, TournamentTypes } from '../models/tournament.model';
import classNames from 'classnames/dedupe';

interface ITournamentLabelProps {
	tournament: ITournamentCompact;
	tournamentType?: TournamentTypes;
	tournamentStyle?: string;
	tournamentViewHref: string;
}

const TournamentLabel = (props: Omit<ITournamentLabelProps, 'cdnUrl' | 'tournamentViewHref'>) => {
	const { tournament, tournamentType } = props;

	const { championshipId } = tournament;

	const { formatMessage } = useIntl();

	const getTranslationId = (): string => {
		if (championshipId) {
			return 'bcs-Championship-Label';
		}
		return 'bcs-Tournaments-Award';
	};

	return (
		<div
			className={classNames(
				`absolute text-white font-sans rounded-r flex justify-center h-[22px] items-center left-[-6.5px] px-2`,
				{
					'sm:top-[18px] top-[12px]': tournamentType,
					'bg-greenLabel-100 w-auto': championshipId,
					'bg-main-500 w-[85px]': !championshipId,
				}
			)}
			style={{ boxShadow: '0px 4px 4px 0px rgba(0 0 0 / 25%)' }}
		>
			<p className='text-sm font-medium'>{formatMessage({ id: getTranslationId() })}</p>
			<div className='absolute w-[6.5px] overflow-hidden left-0 top-[22px]'>
				<div
					className={classNames(`h-5 -rotate-45 transform origin-top-left`, {
						'bg-greenLabel-200': championshipId,
						'bg-mainShadow-100': !championshipId,
					})}
				/>
			</div>
		</div>
	);
};

export default TournamentLabel;
